let initialState = {
  data: 'welcomePage',
  service: '',
  profileRoute: 'publicProfile',
  nestedServiceRoute: 'listing',
  state: ''
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHARE_ROUTE_ACTION':

      return {
        ...state,
        data: action.payload,
        service: action.service
      };


    case 'PROFILE_ROUTE_ACTION':

      return {
        ...state,
        profileRoute: action.payload,
        nestedServiceRoute: action.nestedRoute,
        service: action.service
      };
    case 'NESTED_SERVICE_ROUTE':

      return {
        ...state,
        nestedServiceRoute: action.payload,
      };


    default:
      return state;
  }

}
export default reducer