import { apiTypesName } from "../helper/apiTypes";

export const checkArtistExist = async (email, session) => {
    var artistSerresponse = await fetch(`/api/newStructure/artistServices`, {
        method: "POST",
        body: JSON.stringify({
            type: apiTypesName.GET_ARTIST_AND_SERVICE_DATA,
            payload: {
                email: email,
            },
        }),
    });
    const dataDecodeArtistService = (await artistSerresponse.json())?.response;
    return {
        auth: session ? true : false,
        session,
        artistData: dataDecodeArtistService,
    };
};
