export const ContainerStyles = {
  baseStyle :{
    w: "100%",
    mx: "auto",
    maxW: "unset",
    px: "1rem",
  },
  defaultProps :{

  }
}