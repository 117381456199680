import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { Provider, useSession } from "next-auth/client";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "../config/Store";
import { hotjar } from "react-hotjar";
import Script from "next/script";
import { newTheme } from "../styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import "swiper/swiper.min.css";
import "../styles/pagination.css";
import "../styles/ShortLight.css";
import "swiper/components/thumbs/thumbs.min.css";
import "../styles/pills.scss";
import "../styles/global.scss";
import "../styles/drawer.scss";
import "../styles/ServiceDetails.css";
import "../styles/index.css";
import "../styles/blog-custom.css";
import "../styles/privacy.css";
import { checkArtistExist } from "../lib/commonApi";
import { setArtistData } from "../store/actions/artistAction";
function App({ Component, pageProps }) {
  const hotjarId = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID);

  useEffect(() => {
    hotjar.initialize(hotjarId, 1);
  }, []);
  if (typeof window !== "undefined") {
    if (window.navigator.onLine) {
    } else {

    }
  }
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
        page_path: window.location.pathname,
        });
    `}
      </Script>
      <ReduxProvider store={store}>
        <Provider session={pageProps.session}>
          <ChakraProvider theme={newTheme}>
            <Component {...pageProps} />
            <FetchUserData />
          </ChakraProvider>
        </Provider>
      </ReduxProvider>
    </>
  );
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);
export default wrapper.withRedux(App);
const FetchUserData = () => {
  const dispatch = useDispatch();
  const [session, loadingSession] = useSession();
  useEffect(() => {
    if (session) {
      fetchData();
    }
  }, [loadingSession]);
  const fetchData = async () => {
    try {
      const response = await checkArtistExist(session?.user?.email, session);
      if (response?.artistData) {
        dispatch(setArtistData(response));
      }
    } catch (error) {
    }
  };
  return <></>
}