export const ButtonStyles = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props) => ({
      bg: "brand",
      color: "white",
      border: "1px",
      borderRadius: "5px",
      borderColor: "brand",
      _hover: {
        bg: "white",
        color: "brand",
      },
    }),
    secondary: (props) => ({
      bg: "white",
      color: "brand",
      borderRadius: "5px",
      border: "1px",
      borderColor: "brand",
      _hover: {
        bg: "brand",
        color: "white",

      },
    }),
    
  },
  // default values for `size` and `variant`
  defaultProps: {},
};