import { extendTheme } from "@chakra-ui/react";
import { ContainerStyles as Container } from './components/container'
import { ButtonStyles as Button } from "./components/button";
export const newTheme = extendTheme({
  styles: {
    global: {
      ".mb-50": {
        marginBottom: "50px"
      },
      ".pt-50": {
        paddingTop: "50px"
      },
      ".pb-50": {
        paddingBottom: "50px"
      },
      ".positionRelative": {
        position: "relative"
      },
      ".slick-dots": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "9",
        position: "absolute",
        width: "100%",
        bottom: "92px",
      },
      ".slick-prev" :{      
        borderRadius: '100%',
        height:"30px",
        width:"30px",
        position: "absolute",
        left: "9px",
        top: "80px",
        zIndex: "2"
      },
      ".slick-next" :{      
        borderRadius: '100%',
        position: "absolute",
        right: "12px",
        top: "80px",
        zIndex: "2"
      }
    },
  },
  colors: {
    brand: "#8d6a20",
    black: "#333",
    white: "#ffffff",
    disable: '#BEBEBE',
  },
  fonts: {
    body: "Poppins",
  },

  breakpoints: {
    sm: "750px",
    md: "850px",
    lg: "1260px",
    xl: "1753px",
    xxl:"1900px"
  },
  components: {
    Container,
    Button
  },
})
