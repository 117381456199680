import { actionTypes } from "../actionTypes";

export const setArtistData = (data) => async (dispatch) => {
  try {
    const filteredServiceTypes = [];
    data?.artistData?.serviceTypesList?.map((item) => {
      var isAppend = false;
      data?.artistData?.artistList?.servicesList?.map((ser) => {
        if (ser?.serviceTypeId === item?._id) {
          isAppend = true;
        }
      });
      if (isAppend) {
        filteredServiceTypes?.push(item);
      }
    });
    dispatch({
      type: actionTypes.SET_ARTIST_DATA,
      payload: {
        artistData: data?.artistData?.artistList,
        servicesList: data?.artistData?.artistList?.servicesList,
        serviceTypesList: data?.artistData?.serviceTypesList,
        artistServiceTypes: filteredServiceTypes,
      },
    });
  } catch (error) {
  } finally {
  }
};

export const updateArtistServiceList = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.UPDATE_ARTIST_SERVICE_LIST,
      payload: data,
    });
  } catch (error) {
  } finally {
  }
};
export const addNewArtistService = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.ADD_NEW_ARTIST_SERVICE,
      payload: data,
    });
  } catch (error) {
  } finally {
  }
};
export const deleteArtistService = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.DELETE_ARTIST_SERVICE,
      payload: data,
    });
  } catch (error) {
  } finally {
  }
};
export const artistCoverImage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.ARTIST_COVER_IMAGES,
      payload: data,
    });
  } catch (error) {
  } finally {
  }
};
export const artistlookbookImages = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.ARTIST_LOOK_BOOK_IMAGES,
      payload: data,
    });
  } catch (error) {
  } finally {
  }

  
};
export const artistDataInRedux = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.ARTIST_DATA,
      payload: data,
    });
  } catch (error) {
  } finally {
  }
};
