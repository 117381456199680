let initialState = {
  data: '',
  category: '',
  serviceData: [],
  services: [],
  status: '',
  state: '',
  insertedId: '',
}
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHARE_DATA_ACTION':

      return {
        ...state,
        data: action.payload,
      };
    case 'SHARE_INSERTED_ID':

      return {
        ...state,
        insertedId: action.payload,
      };
    case 'SHARE_STATUS_ACTION':

      return {
        ...state,
        status: action.payload,
      };
    case 'SHARE_STATE_ACTION':

      return {
        ...state,
        state: action.payload,
      };
    case 'SHARE_CATEGORY':

      return {
        ...state,
        category: action.payload,
      };
    case 'SHARE_SERVICE_DATA':

      return {
        ...state,
        serviceData: action.payload,
      };
    case 'ADDED_SERVICES':

      return {
        ...state,
        services: action.payload,
      };
    default:
      return state;
  }

}
export default dataReducer