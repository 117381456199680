import { combineReducers } from "redux";

import dataReducer from "./reducers/dataReducer";
import reducer from "./reducers/reducer";
import artistReducer from "./reducers/artistReducer";

const rootReducer = combineReducers({
  reducer,
  dataReducer,
  artistReducer,
});

export default rootReducer;
