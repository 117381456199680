import { actionTypes } from "../actionTypes";

let initialState = {
  artistData: null,
  servicesList: [],
  serviceTypesList: [],
  artistServiceTypes: [],
  coverImages: [],
  lookBookList: [],
};

const artistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ARTIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_ARTIST_SERVICE_LIST:
      const updatedList = state.servicesList.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });

      return {
        ...state,
        servicesList: updatedList,
      };
    case actionTypes.ADD_NEW_ARTIST_SERVICE:
      return {
        ...state,
        servicesList: [action.payload, ...state.servicesList],
      };
    case actionTypes.DELETE_ARTIST_SERVICE:
      const removeItem = state.servicesList.filter((item) => item?._id !== action.payload);
      return {
        ...state,
        servicesList: removeItem,
      };
    case actionTypes.ARTIST_COVER_IMAGES:

      return {
        ...state,
        coverImages: action.payload,
      };
      case actionTypes.ARTIST_LOOK_BOOK_IMAGES:
      return {
        ...state,
        lookBookList: action.payload,
      };
      case actionTypes.ARTIST_DATA:
      return {
        ...state,
        artistData: action.payload,
      };
    default:
      return state;
  }
};

export default artistReducer;
